import React from "react";
import "../page_styles/about.css"; // import your CSS file
import img2 from "../assets/images/artist_statement_photos/aboutericheadshot.jpg";
import img1 from "../assets/images/artist_statement_photos/mixingpaint.jpg";
import img3 from "../assets/images/artist_statement_photos/ericpainting.jpg";


const About = () => {
  return (
    <div className="about-container">
      <div className="about-text">
        <h1>ARTIST STATEMENT</h1>
        <img
          src={img2}
          alt="Artistic Image 2"
          className="about-image about-image-left"
        />
        <p>
          Eric Warren is an Artist &amp; Paint Maker. Growing up locally in San
          Diego, Eric draws little to no artistic inspiration from the beautiful
          scenery of beaches or sunsets. Eric found painting as a coping
          mechanism, initially he despised oil painting, but found he hated
          acrylics even more.
        </p>
      
        <i>
          “Painting runs in my bloodline, it’s almost as if I’m forced to do
          it.”
        </i>
     

        <p>
          Since beginning his artistic journey in 2021, Eric has become obsessed
          with oil painting &amp; turning natural materials into paint.
        </p>
        <i>
          “I find great pleasure in the outdoors, especially turning it into
          paint.”
        </i>
      
        <p>
          Eric purely designs artwork from his experiences &amp; headspace,
          leaning into surrealism &amp; impressionism.
        </p>
        <i>
          “I love to play with the human figure &amp; put it in curious
          positions, while using colors to psychologically toy with reaction.”
        </i>
        <p>
          Eric often uses warm tones in his paintings to invite the audience in,
          but after examining the painting &amp; understanding the material,
          emotions circulate.
        </p>
        <img
          src={img1}
          alt="Artistic Image 1"
          className="about-image about-image-right"
        />
        <i>
          “I want the viewer to feel drawn in by a warm color only to realize
          what they are seeing is uncomfortable.”
        </i>
        <p>
          My original goal with painting was to get a lot of shit out of my head
          &amp; cope with bipolar, speaking wasn’t working. I didn’t improve
          until I picked up a brush.
        </p>
        <p>
          Now I hope to inspire others to give it a try, any medium, any style.
          Maybe even making your own paint, a way to reconnect with process,
          giving the ‘I need it now’ mentality a rest.
        </p>
     
      <h6>Contact: ewdgallery@gmail.com</h6>
      </div>
     
      
    </div>
  );
};

export default About;
