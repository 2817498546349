import React from "react";
import "./App.css";
import Navbar from './components/Navbar';
import { HashRouter as Router, Routes, Route , Switch} from 'react-router-dom';

//Navbar
import Home from './pages/home'
import Gallery from './pages/gallery'
import About from './pages/about'

function App() {
  return (
   //basename=""
    <Router > 
    <Navbar />
    <Routes>
  
        <Route path='/' element={<Home />} />
        <Route path='/gallery' element={<Gallery />} />
        <Route path='/about' element={<About />} />
     
    </Routes>
</Router>
  );
}

export default App;
