import React, { useState } from "react";
import Fade from "react-reveal/Fade";
import { Element } from "react-scroll";
import "../page_styles/gallery.css";
import artworks from "../assets/art.json"; // Import the JSON data

/**
 * A functional component that displays an individual gallery image.
 * @param imageSrc: The source URL of the image.
 * @param title: The title of the artwork.
 *  @param artwork: Finds the matching artwork data from the JSON file based on the title.
 * @returns
 */
function GalleryImage({ imageSrc, title, onClick }) {
  const artwork = artworks.find((art) => art.title === title) || {};

  return (
    <li>
      {/* <Fade> */}
        <img
          className="galleryImage"
          src={imageSrc}
          alt={title}
          loading="lazy"
          onClick={() => onClick(imageSrc, title)}
        />
        <div className="overlay">
          <span>{title}</span>
        </div>
        <div>
          <span>
            {artwork.medium || "Unknown medium"} {artwork.dimensions || ""}
          </span>
        </div>
        <div>
          <span>{artwork.year || "Unknown year"}</span>
        </div>
        <div>
          <span>
            <a href="https://ericwarrenart.myshopify.com/">
              {artwork.availability || "Not for sale"}
            </a>
          </span>
        </div>
      {/* </Fade> */}
    </li>
  );
}

const artworkContext = require.context(
  "../assets/artworks",
  false,
  /\.(jpg|jpeg|png)$/
);

// Map context keys to actual image paths
const artworkImages = artworkContext.keys().map(artworkContext);
/**
 *
 * @returns
 */
const Gallery = () => {
  // State for modal
  const [isModalOpen, setModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedTitle, setSelectedTitle] = useState(null);

  const handleImageClick = (imageSrc, title) => {
    setSelectedImage(imageSrc);
    setSelectedTitle(title);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
    setSelectedImage(null);
    setSelectedTitle(null);
  };

  return (
    <div className="gallery-container">
      {isModalOpen && (
        <div className="modal" onClick={closeModal}>
              {/* <h2>{selectedTitle}</h2> */}
          <img
            className="modalImage"
            src={selectedImage}
            alt="Zoomed Artwork"
          />
      
        </div>
      )}
      <Element name="gallery">
        <h1>featured artworks</h1>
        <ul className="image-gallery">
          {artworks.map((artwork, index) => {
            const imageSrc = artworkContext(`./${artwork.title}.jpg`); // assuming all images are jpg, adjust accordingly
            const imageTitle = artwork.title;
            // const fileName = artwork.split("/").pop();
            // const title = fileName.split(".")[0]; // Removing the extension to use it as title
            return (
              <GalleryImage
                key={index}
                imageSrc={imageSrc}
                title={imageTitle}
                onClick={handleImageClick}
              />
            );
          })}
        </ul>
      </Element>
    </div>
  );
};

export default Gallery;
