import React, { useEffect, useState } from "react";
import "../page_styles/home.css";
import headshot from "../assets/images/headshot.jpg";
import home1 from "../assets/images/artist_statement_photos/hom1.jpg";
import home2 from "../assets/images/artist_statement_photos/home2.jpg";
import home3 from "../assets/images/artist_statement_photos/home3.jpg";
import home4 from "../assets/images/artist_statement_photos/ericpainting.jpg";
import instagram from "../assets/images/icons/instagram-icon.png";

import tiktok from "../assets/images/icons/tiktok-icon.png";
import redubble from "../assets/images/icons/redbubble.png";
import youtube from "../assets/images/icons/youtube.png";

// Slide show
const images = [
  headshot,
  // slideshowImg1,
  // slideshowImg2,
  // slideshowImg3
];

const Home = () => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  useEffect(() => {
    const timer = setTimeout(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 10000);

    return () => clearTimeout(timer); // Clear the timer if the component is unmounted or the effect re-runs
  }, [currentImageIndex]);

  return (
    <div className="content">
      <div id="banner-image">
        <img
          id="big-image"
          src={images[currentImageIndex]}
          alt="Slideshow Image"
        ></img>
      </div>
      <div className="home-container">
        <h1>ERIC WARREN</h1>
        <h2>Artist and Paint Maker</h2>
        <div className="home-images-container">
          <img src={home3}></img>
          <img src={home2}></img>

          <img src={home4}></img>
        </div>
        <address>Contact: ewdgallery@gmail.com</address>
        <div className="social-media-container">
          <a href="https://www.instagram.com/ericwarrenartist/">
            <img width="32px" src={instagram}></img>
          </a>
          <a href="https://www.tiktok.com/@ericwarrenart">
            <img width="32px" src={tiktok}></img>
          </a>
          <a href="https://www.youtube.com/@EricWarrenART">
            <img width="32px" src={youtube}></img>
          </a>
          {/* <a href="https://www.redbubble.com/people/ewdgallery/shop">
            <img width="24px" src={redubble}></img>
          </a> */}
        </div>

        <div className="siteMap"></div>
      </div>
    </div>
  );
};

export default Home;
