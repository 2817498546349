import { NavLink as Link } from "react-router-dom";
import styled from "styled-components";
import bg from "../../assets/images/SpecBG.jpg";

export const Nav = styled.nav`
  @font-face {
    font-family: "Avenir";
    src: url("../assets/fonts/Avenir\ Next\ LT\ Pro\ Regular.otf");
  }

  @import url("https://fonts.googleapis.com/css2?family=Assistant:wght@200;300;400;500;600;700;800&family=Playfair+Display:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

  background-image: url(${bg});
  // box-shadow: 6px 6px 14px 0 rgba(0, 0, 0, 0.2),
  //   -8px -8px 18px 0 rgba(255, 255, 255, 0.55);
  height: 100px;
  display: flex;
  justify-content: space-around;
  padding: 0.5rem calc((100vw - 1000px) / 2);
  z-index: 12;
  font-family: "Assistant", "Avenir", "Playfair Display", serif;
  position: sticky;
  top: 0;

  @media screen and (max-width: 768px) {
    background: none;
    // background-image: url(${bg});
    height: 0px;
    justify-content: space-between;
    box-shadow: none;
  }
`;
// Text
export const NavLink = styled(Link)`
  color: white;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0 1.5rem;
  margin-left: 1.1rem;
  margin-right: 1.1rem;
  height: 100%;
  cursor: pointer;
  font-size: 1.2rem;

  border-bottom: 2px solid transparent;
  transition: 0.2s;
  cursor: pointer;
  &:hover {
    border-bottom: 2px solid #d0d0d0;
  }

  &.active {
    border-bottom: 2px solid #d0d0d0;
  }

  @media screen and (max-width: 768px) {
    padding: 0 1rem; // or padding: 0 0.5rem;
    height: 100px;
  }
`;

export const ExternalLink = styled.a`
  color: white;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0 1.5rem;
  height: 100%;
  position: relative;
  cursor: pointer;
  font-size: 1.2rem;

  &:hover {
  }

  &.active {
    // border-bottom: 3px solid white;
  }

  @media screen and (max-width: 768px) {
    padding: 0 1rem; // or padding: 0 0.5rem;
    height: 100px;
  }
`;

export const NavMenu = styled.div`
  display: flex;
  align-items: center;
  margin-right: -24px;

  @media screen and (max-width: 768px) {
    display: flex;
    flex-direction: column;
    width: 90%; /* Adjusted to 90% of the viewport width */
    height: 100vh; /* Adjusted to 100% of the viewport height */
    position: absolute;
    top: 0; /* Adjusted to the top of the viewport */

    left: ${({ open }) =>
      open
        ? "0%"
        : "-100%"}; /* Adjusted to center the navbar, assuming it's opening from the left */
    transition: left 0.5s ease;
    background-image: url(${bg});
    z-index: -1;
  }
`;

export const MobileIcon = styled.div`
  display: none;

  @media screen and (max-width: 768px) {
    display: block;
    font-size: 1.8rem;
    cursor: pointer;
    padding: 25px;
    margin: none;
  }
`;

export const LogoContainer = styled.div`
  position: absolute;
  top: -25px; /* Adjust this value to position your logo above the navbar */
  left: 90%;
  transform: translateX(-50%);
  width: 100px; /* Adjust width and height to your preference */
  // height: 80px;
  border-radius: 50%;
  overflow: hidden;
  z-index: 15; /* Ensures the logo appears on top of other elements */
`;
