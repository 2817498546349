import React, { useState } from "react";

import {
  Nav,
  NavLink,
  NavMenu,
  MobileIcon,
  ExternalLink,
  LogoContainer,
} from "./NavbarElements";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBars,
  faTimes,
  faAngleDown,
  faAngleUp,
} from "@fortawesome/free-solid-svg-icons";
import logo from "../../assets/images/avatar.jpg";

const Dropdown = () => {
  return (
    <div
      style={{
        position: "absolute",
        top: "100%",
        left: "0",
        width: "200px",
        marginTop: "10px",
        backgroundColor: "black",

        border: "1px solid black",
        boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
      }}
    >
      <a
        href="https://ericwarrenart.myshopify.com/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <div style={{ color: "white", marginLeft: "2px", padding: "2px" }}>
          Prints
        </div>
      </a>
      <hr />
      <a
        href="https://www.redbubble.com/people/ewdgallery/shop"
        target="_blank"
        rel="noopener noreferrer"
      >
        <div style={{ color: "white", marginLeft: "2px", padding: "2px" }}>
          Stickers
        </div>
      </a>
    </div>
  );
};

const Navbar = () => {
  const [open, setOpen] = useState(false);

  const toggleOpen = () => {
    setOpen(!open);
  };

  // piece of state to manage the visibility of the dropdown.
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const toggleDropdown = () => {
    setDropdownOpen(!isDropdownOpen);
  };

  const turnOffDropdown = () => {
    setDropdownOpen(false);
  };

  return (
    <>
      <Nav>
        <MobileIcon onClick={toggleOpen}>
          {open ? (
            <FontAwesomeIcon
              icon={faTimes}
              style={{
                stroke: "white",
                strokeWidth: "15px",
                color: "black",
                alignSelf: "flex-start",
              }}
            />
          ) : (
            <FontAwesomeIcon
              icon={faBars}
              style={{
                stroke: "white",
                strokeWidth: "15px",
                color: "black",
                alignSelf: "flex-start",
              }}
            />
          )}
        </MobileIcon>
        <NavMenu open={open}>
          <NavLink
            to="/"
            activeStyle
            onClick={() => {
              toggleOpen();
              turnOffDropdown();
            }}
          >
            HOME
          </NavLink>
          <NavLink
            to="/gallery"
            activeStyle
            onClick={() => {
              toggleOpen();
              turnOffDropdown();
            }}
          >
            GALLERY
          </NavLink>

          <ExternalLink onClick={toggleDropdown}>
            SHOP{" "}
            <FontAwesomeIcon
              style={{ marginLeft: "5px", marginTop: "5px" }}
              icon={isDropdownOpen ? faAngleUp : faAngleDown}
            />
            {isDropdownOpen && <Dropdown />}
          </ExternalLink>

          <NavLink
            to="/about"
            activeStyle
            onClick={() => {
              toggleOpen();
              turnOffDropdown();
            }}
          >
            ABOUT
          </NavLink>
        </NavMenu>
        <LogoContainer>{/* <img src={logo}></img> */}</LogoContainer>
      </Nav>
    </>
  );
};

export default Navbar;
